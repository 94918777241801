import { Component, OnInit } from "@angular/core";
import { single } from "app/pipes/single";
import { GraphService } from "app/services/graph.service";
import * as Chartist from "chartist";
@Component({
  selector: "app-piechart",
  templateUrl: "./piechart.component.html",
  styleUrls: ["./piechart.component.css"],
})
export class PiechartComponent implements OnInit {
  data: any[];
  // options
  gradient: boolean = true;
  showLegend: boolean = false;
  showLabels: boolean = true;
  isDoughnut: boolean = true;
  legendPosition: string = "right";
  showXAxisLabel;
  showYAxisLabel;
  xAxisLabel;
  yAxisLabel;
  showXAxis;
  showYAxis;
  /*  colorScheme = {
    domain: ["#5AA454", "#A10A28", "#C7B42C", "#AAAAAA"],
  }; */

  constructor(private service: GraphService) {}
  ngOnInit(): void {
    this.service.venueCountsByDistrict().then((res) => {
      this.data = res;
    });
  }

  onSelect(data): void {
  
  }

  onActivate(data): void {
    
  }

  onDeactivate(data): void {
   
  }
}
