import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, MatPaginatorModule } from "@angular/material/paginator";
import { Router, ActivatedRoute } from "@angular/router";
import { EventManageService } from "app/services/eventmanage.service";
import { UtilsService } from "app/services/utils.service";
import { VenueService } from "app/services/venue.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-bandobust-list",
  templateUrl: "./bandobust-list.component.html",
  styleUrls: ["./bandobust-list.component.css"],
})
export class BandobustListComponent implements OnInit, AfterViewInit {
  pageSize;
  length;
  @ViewChild(MatPaginatorModule) paginator: MatPaginator;
  dataSource: any;
  searchForm: FormGroup;
  displayedColumns: string[] = [
    "id",
    "venuename",
    "latitude",
    "longitude",
    "districtid",
    "eventstart",
    "eventend",
    "status",
  ];
  eventtypes: any;
  districts: any;
  urlParam: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: EventManageService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private utils: UtilsService,
    private formBuilder: FormBuilder,
    private venueService: VenueService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.urlParam = params;
    });
  }
  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.getUtils();
  }
  getUtils() {
    this.utils.getEventTypes().then((res) => {
      this.eventtypes = res;
    });

    if (this.urlParam.id) {
      this.venueService.getVenueByEventId(this.urlParam.id).then((res) => {
        this.dataSource = res;
      });
    } else {
    const startdate=new Date();
    this.venueService.getVenuesByFilters({startdate:startdate}).then(res=>{
      this.dataSource = res;
    })
      this.venueService.getAllVenues().then((res) => {
       
      });
    }

    this.utils.getDistricts().then((res) => {
      this.districts = res;
    });
  }
  planBandobust(element) {
    this.router.navigateByUrl("/dashboard/event/bandobust-plan", {
      state: {
        id: element.id,
        districtid: element.districtid,
        lat: element.latitude,
        lng: element.longitude,
        edit:false
      },
    });
  }
  getDistrictName(id) {
    const o = this.districts.find((x) => x.id === id);
    return o.name;
  }
  getEventName(id) {
    /*this.service.getEventbyId(id).toPromise();
    return data.eventname; */
  }
  viewBandobust(element) {
    this.router.navigateByUrl("/dashboard/event/bandobust-plan", {
      state: {
        id: element.id,
        districtid: element.districtid,
        lat: element.latitude,
        lng: element.longitude,
        edit:true
      },
    });
  }
}
