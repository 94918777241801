import {
  NgxMatDateFormats,
  NGX_MAT_DATE_FORMATS,
} from "@angular-material-components/datetime-picker";
import { ValueConverter } from "@angular/compiler/src/render3/view/template";
import {
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular/material-moment-adapter";
import { ThemePalette } from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute } from "@angular/router";
import { DateValidators } from "app/pipes/DateValidator";
import { UtilsService } from "app/services/utils.service";
import * as moment from "moment";
import { LocationSelectorComponent } from "../location-selector/location-selector.component";
import { VehicleSelectorComponent } from "../vehicle-selector/vehicle-selector.component";
const CUSTOM_MOMENT_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: "L, LT",
  },
  display: {
    dateInput: "DD/MM/YYYY HH:mm",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};
@Component({
  selector: "app-venue-selectors",
  templateUrl: "./venue-selectors.component.html",
  styleUrls: ["./venue-selectors.component.css"],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
})
export class VenueSelectorsComponent implements OnInit {
  /*  @ViewChild("vehicleselector", { static: false })
  vehicleselector: VehicleSelectorComponent; */
  @ViewChildren("vehicleselector", { read: VehicleSelectorComponent })
  public vehicleselector: QueryList<VehicleSelectorComponent>;
  @Input() data: any;
  form: FormGroup;
  public disableMinute = false;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = true;
  public enableMeridian = false;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = "primary";
  id: any;
  districts: any;
  designation: any;
  units: any;
  constructor(
    private _snackBar: MatSnackBar,
    private utils: UtilsService,
    private route: ActivatedRoute,
    private dailog: MatDialog,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.getUtils();
    this.initializeForm();
  }
  getUtils() {
    this.utils.getDistricts().then((res) => {
      this.districts = res;
    });
    this.utils.getDesignation().then((res) => {
      this.designation = res;
    });
    this.utils.getUtils("units").then((res) => {
      this.units = res;
    });
  }

  get f(): any {
    return this.form.controls;
  }
  initializeForm(): void {
    this.form = this.formBuilder.group({
      venues: new FormArray([this.initializeFormControls()]),
    });
  }
  initializeFormControls(): any {
    return new FormGroup({
      id: new FormControl(null),
      venuename: new FormControl(null, [Validators.required]),
      latitude: new FormControl(null, [Validators.required]),
      longitude: new FormControl(null, [Validators.required]),
      eventstart: new FormControl(null, [Validators.required]),
      eventend: new FormControl(null, [Validators.required]),
      status: new FormControl("pending"),
      eventid: new FormControl(this.data.eventid, [Validators.required]),
      unitid: new FormControl(null),
      districtid: new FormControl(this.data.districtid, [Validators.required]),
      isvehiclereqd: new FormControl(null),
      isnondefreqd: new FormControl(null),
      vehicles: new FormControl(null),
      nondefunits: new FormControl(null),
    } ,{
      validators: Validators.compose([
        DateValidators.dateLessThan("eventstart", "eventend", {
          eventstart: true,
        }),
      
      ]),
    });
  }

  addVenue(): void {
    if (this.form.invalid) {
      return;
    }
    this.f.venues.push(this.initializeFormControls());
    this.f.venues.updateValueAndValidity();
  }
  removeVenue(i): void {
    this.f.venues.removeAt(i);
    this.f.venues.updateValueAndValidity();
  }
  getDefName(district, designation) {
    return this.designation?.filter((x) => x.id === designation).name;
  }
  openLocationSelector(i) {
    const dialogRef = this.dailog.open(LocationSelectorComponent, {
      width: "50vw",
      data: { district: this.getDistrictName(this.data.districtid) },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.f.venues.controls[i].patchValue({
          latitude: result.lat,
          longitude: result.lng,
        });
      }
    });
  }
  unitChanged(id, i) {
    this.utils.getUsersByUnitId(id).then((res) => {
      this.f.venues.controls[i].patchValue({ usersfromapi: res });
    });
  }
  notifyVehicleValues($event, i) {
    this.f.venues.controls[i].patchValue({ vehicles: $event.vehicles });
  }
  notifyNonDefValues($event, i) {
    this.f.venues.controls[i].patchValue({ nondefunits: $event.nondefunits });
  }
  setStart(date) {
    const newdate = moment(date).add(0, "h").add(0, "m").toDate();
    return newdate;
  }
  setEnd(date) {
    const newdate = moment(date).add(23, "h").add(59, "m").toDate();
    return newdate;
  }
  getDistrictName(district) {
    const dis = this.districts?.filter((x) => x.id === district);
    return dis[0].name;
  }
}
