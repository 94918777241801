import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";

import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "app/services/auth.service";
import { common } from "app/utils/common";
import { AngularFireAuth } from "@angular/fire/auth";

const phonePattern = "^[0-9]{10}$";
const numberPattern = "^[0-9]*$";
const pinCodePattern = "^[0-9]{6}$";
const aadhaarPattern = "^[0-9]{12}$";
const emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  hide = true;
  loginForm: FormGroup;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private auth: AuthService,
    public afAuth: AngularFireAuth
  ) {}
  ngOnInit(): void {
    if (this.auth.getToken()) {
      this.router.navigateByUrl("/dashboard");
    }
    this.loginForm = this.formBuilder.group({
      mobileno: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(common.phonePattern),
        ]),
      ],
      password: ["", [Validators.required]],
    });
  }
  login(): void {
    if (this.loginForm.invalid) {
      this.toastr.warning("Email and password required");
      return;
    }
    this.auth.login(this.loginForm.getRawValue()).then((res) => {
      this.auth.setToken(
        res.token,
        res.firebaseEmail,
        res.firebasePassword,
        res.districtId
      );
      this.afAuth.setPersistence("session").then(
        () => {
          this.afAuth.signInWithEmailAndPassword(
            res.firebaseEmail,
            res.firebasePassword
          );
          this.router.navigate(["/dashboard"]);
        },
        (err) => {
          this.toastr.error(err);
        }
      );
    });
  }
  onKeydown(event) {
    if (event.key === "Enter") {
      this.login();
    }
  }
  goToApp(){
    const url = "intent://mybandobust.com/#Intent;scheme=https;package=in.nagaland.callyourcop;end";
    window.location.replace(url);
  }
}
