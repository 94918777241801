import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthService } from "app/services/auth.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private service: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.service.getToken()) {
      const authReq = req.clone({
        headers: req.headers
          .set("Authorization", "Bearer " + this.service.getToken())
          .set("Type", "dashboard"),
      });
      return next.handle(authReq);
    }
    return next.handle(req);
  }
}
