import { UnauthorizedComponent } from "./../components/unauthorized/unauthorized.component";
import { MainComponent } from "./main/main.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { ChangePasswordComponent } from "app/components/change-password/change-password.component";

const routes: Routes = [
  {
    path: "",
    component: DashboardComponent,
    children: [
      { path: "", component: MainComponent },
      {
        path: "change-password",
        component: ChangePasswordComponent,
      },
      {
        path: "event",
        loadChildren: () =>
          import("../event-management/event-management.module").then(
            (m) => m.EventManagementModule
          ),
      },
      {
        path: "management",
        loadChildren: () =>
          import("../management/management.module").then(
            (m) => m.ManagementModule
          ),
      },
      {
        path: "utils",
        loadChildren: () =>
          import("../utils/utils.module").then((m) => m.UtilsModule),
      },
      {
        path: "alerts",
        loadChildren: () =>
          import("../alerts/alerts.module").then((m) => m.AlertsModule),
      },
      {
        path: "emergency-numbers",
        loadChildren: () => import("../emergency-numbers/emergency-numbers.module").then((m) => m.EmergencyNumbersModule)
      },
      {
        path: "tourist-tips",
        loadChildren: () => import("../tourist-tips/tourist-tips.module").then((m) => m.TouristTipsModule)
      },

      {
        path: "**",
        component: UnauthorizedComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule { }
