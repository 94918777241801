import {
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { EventManageService } from "app/services/eventmanage.service";
import { UtilsService } from "app/services/utils.service";
import { VenueService } from "app/services/venue.service";
import { ToastrService } from "ngx-toastr";
import { VenueSelectorsComponent } from "../helpers/venue-selectors/venue-selectors.component";

@Component({
  selector: "app-venue-allocation",
  templateUrl: "./venue-allocation.component.html",
  styleUrls: ["./venue-allocation.component.css"],
})
export class VenueAllocationComponent implements OnInit {
  @ViewChildren("viewRef", { read: VenueSelectorsComponent })
  public viewRefs: QueryList<VenueSelectorsComponent>;

  id: any;
  districts: any;

  locations: any;
  o: { [k: string]: any };

  constructor(
    private route: ActivatedRoute,
    private service: VenueService,
    private utils: UtilsService,
    private toastr: ToastrService,
    private router: Router,
    private eventService: EventManageService
  ) {
    if (!this.router.getCurrentNavigation().extras.state) {
      this.router.navigateByUrl("/dashboard/event");
    }

    this.o = this.router.getCurrentNavigation().extras.state;

    this.id = this.o.id;
  }

  ngOnInit(): void {
    this.getUtils();

    this.eventService.getEventbyId(this.id).then((res) => {
      this.locations = res.locations;
      
    });
  }
  getUtils() {
    this.utils.getDistricts().then((res) => {
      this.districts = res;
    });
  }

  getDistrictNames(id) {
    if (Array.isArray(id)) {
      let list = [];
      id.forEach((o) => {
        let v = this.districts.find((x) => x.id === o);
        list.push(v.name);
      });
      return list;
    } else {
      let o = this.districts.find((x) => x.id === id);
      return o.name;
    }
  }
  onSubmit() {
    let vo = [];
    for (const x of this.viewRefs.toArray()) {
      if (x.form.invalid) {
        this.toastr.warning("Incomplete or Invalid details");
        return;
      }

      let o = x.f.venues.getRawValue();

      for (const element of o) {
        element.usersfromapi = null;
        vo = [...vo, element];
      }
    }

    this.service.addVenues(vo, this.id).then(
      (res) => {
        
        this.toastr.success("Successfully added venues");
        this.router.navigate(["../../"], { relativeTo: this.route });
      },
      (err) => {
        this.toastr.error(err);
      }
    );
  }
}
