import { AgmMap } from "@agm/core";
import {
  NgxMatDateFormats,
  NGX_MAT_DATE_FORMATS,
} from "@angular-material-components/datetime-picker";
import { NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular-material-components/moment-adapter";
import { trigger, transition, style, animate } from "@angular/animations";
import {
  AfterViewInit,
  Component,
  HostListener,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, NgModel, Validators } from "@angular/forms";
import { ThemePalette } from "@angular/material/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { BandobustService } from "app/services/bandobust.service";
import { EventManageService } from "app/services/eventmanage.service";
import { UtilsService } from "app/services/utils.service";
import { VenueService } from "app/services/venue.service";
import { EventEmitter } from "events";
import { ToastrService } from "ngx-toastr";
import { BandobustSelectorComponent } from "../helpers/bandobust-selector/bandobust-selector.component";
import { EventService } from "../helpers/service/event.service";
const CUSTOM_MOMENT_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: "L, LT",
  },
  display: {
    dateInput: "DD/MM/YYYY HH:mm",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};
@Component({
  selector: "app-bandobust",
  templateUrl: "./bandobust.component.html",
  styleUrls: ["./bandobust.component.css"],
  animations: [
    trigger("fadeInOut", [
      transition(":enter", [
        // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 })),
      ]),
      transition(":leave", [
        // :leave is alias to '* => void'
        animate(500, style({ opacity: 0 })),
      ]),
    ]),
  ],
  providers: [
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_FORMATS },
    {
      provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: { useUtc: true },
    },
  ],
})
export class BandobustComponent implements OnInit, AfterViewInit {
  public mapStyles = [
    {
    "featureType": "poi",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
      ]
    }
  ]
  @ViewChild("bandobustselector", { static: false })
  bandobustselector: BandobustSelectorComponent;
  o;
  reddot = "http://maps.google.com/mapfiles/ms/icons/red.png";
  greendot = "http://maps.google.com/mapfiles/ms/icons/green.png";
  yellowdot = "http://maps.google.com/mapfiles/ms/icons/yellow.png";
  lat = 25.67;
  lng = 94.12;
  mapsection = false;
  venueobject: any;
  zoom = 15;
  agmMarkers = [];
  fullscreen = false;
  @Output() public event = new EventEmitter();
  venueid: any;
  units: any;
  @HostListener("document:keydown.escape", ["$event"]) onKeydownHandler(
    event: KeyboardEvent
  ) {
    this.fullscreen ? (this.fullscreen = !this.fullscreen) : null;
  }

  constructor(
    private _snackBar: MatSnackBar,
    private eventService: EventService,
    private route: ActivatedRoute,
    private service: VenueService,
    private utils: UtilsService,
    private bndbstService: BandobustService,
    private toastr: ToastrService,
    private router: Router
  ) {
    if (!this.router.getCurrentNavigation().extras.state) {
      this.router.navigate(["../bandobust"], { relativeTo: this.route });
    }
    this.o = this.router.getCurrentNavigation().extras.state;
  }
  ngAfterViewInit(): void {
    this.getUtils();
  }

  async ngOnInit() {
    this.venueid = this.o?.id;
    this.lat = Number(this.o.lat);
    this.lng = Number(this.o.lng);
    this.fullscreenMap();
    if (this.o.edit) {
      const venue = await this.service.getVenueById(this.o.id);
      
      venue.bandobust.forEach((element) => {
        this.bandobustselector.addLocationFromApi(element);
      });
    }
  }
  addMarker(event) {
    this.bandobustselector.addLocation(event.coords.lat, event.coords.lng);
  }
  removeMarker(marker) {
    this.bandobustselector.removeLocation(marker);
  }

  markerDragEnd(i, event) {
    this.bandobustselector.f.locations.controls[i].patchValue({
      lat: event.coords.lat,
      lng: event.coords.lng,
    });
    this.bandobustselector.setLocationName(
      event.coords.lat,
      event.coords.lng,
      i
    );
  }
  resetMarkers() {
    if (
      confirm("Are you sure to reset markers? This cannot be reverted back.")
    ) {
      this.bandobustselector.f.locations.clear();
    }
    return;
  }
  toggleSection() {
    this.mapsection = !this.mapsection;
  }
  onSubmit() {
    if (this.bandobustselector.form.invalid) {
      this.toastr.warning("Please complete details");
      return;
    }
    const l = this.bandobustselector.form.getRawValue();

    this.bndbstService.addBandobusts(l.locations, this.venueid).then(
      (res) => {
        this.toastr.success("Bandobust successful");
        this.router.navigate(["../bandobust"], { relativeTo: this.route });
      },
      (err) => {
        this.toastr.error(err);
      }
    );
  }
  onAutocompleteSelected(event) {}

  fullscreenMap() {
    this.fullscreen = !this.fullscreen;
    if (this.fullscreen) {
      this._snackBar.open("Press ESC to exit full screen", void 0, {
        duration: 2000,
        panelClass: "my-custom-snackbar",
      });
    }
  }

  getUtils() {
    this.service.getVenueById(this.venueid).then((x) => {
      this.lat = x.latitude;
      this.lng = x.longitude;
    });
    this.bndbstService.getAppUsersByDistrict(this.o.districtid).then((res) => {
      
      this.units = res;
    });
  }

  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          this.lng = +pos.coords.longitude;
          this.lat = +pos.coords.latitude;
        },
        (err) => {
          /* Location permission error here  */
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }
  getIndex(i) {
    return (i + 1).toString();
  }
  outputCordinates($event) {
    this.bandobustselector.addLocation($event.lat, $event.lng);
  }
}
