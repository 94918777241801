import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, MatPaginatorModule } from "@angular/material/paginator";
import { ManagementService } from "app/services/management.service";
import { UtilsService } from "app/services/utils.service";
import { MenuSelectorComponent } from "../menu-selector/menu-selector.component";

@Component({
  selector: "app-menu-management",
  templateUrl: "./menu-management.component.html",
  styleUrls: ["./menu-management.component.css"],
})
export class MenuManagementComponent implements OnInit {
  pageSize;
  length;
  @ViewChild(MatPaginatorModule) paginator: MatPaginator;
  dataSource: any;
  searchForm: FormGroup;
  displayedColumns: string[] = [
    "unitid",
    "employeename",
    "email",
    "mobileno",
    "designation",
    "enabled",
    "action",
  ];
  units: any;
  designations: any;
  constructor(
    private service: ManagementService,
    private utils: UtilsService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.getUtils();
    this.service.getUsers({ showEnabledOnly: true }).then((res) => {
      this.dataSource = res;
    });
  }
  getUtils() {
    this.utils.getUtils("units").then((res) => {
      this.units = res;
    });
    this.utils.getDesignation().then((res) => {
      this.designations = res;
    });
  }
  getUnitName(id) {
    const o = this.units.find((x) => x.id === id);
    return o.name;
  }
  getDesignationName(id) {
    const o = this.designations.find((x) => x.id === id);
    return o.name;
  }
  editAccess(elemement) {
    const dialogRef = this.dialog.open(MenuSelectorComponent, {
      width: "80vw",
      height: "80vh",
      data:elemement
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }
}
