import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "./../../environments/environment";
import { AuthService } from "./auth.service";

const roles = [
  {
    id: "DEF",
    name: "DEF",
  },
  {
    id: "STATE",
    name: "STATE",
  },
];
@Injectable({
  providedIn: "root",
})
export class UtilsService {
  adminBaseurl = environment.base_url + "secured/admin";
  authBaseUrl = environment.base_url + "utils/";
  utilsBaseUrl = environment.base_url + "secured/utils";
  userbaseUrl = environment.base_url + "secured/users";
  constructor(
    private http: HttpClient,
    private router: Router,
    private auth: AuthService
  ) { }
  getEventTypes() {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };
    return this.http
      .post<any>(this.utilsBaseUrl + "/eventtypes", "", httpOptions)
      .toPromise();
  }
  getDefLists(vo) {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };

    return this.http
      .post<any>(this.adminBaseurl + "/search/defs", vo, httpOptions)
      .toPromise();
  }
  getDesignation() {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
        type: 'designation',
      }),
    };

    return this.http
      .post<any>(this.utilsBaseUrl + "/get/" + 'designation', "", httpOptions)
      .toPromise();
  }
  getDistricts() {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };
    return this.http
      .post<any>(this.utilsBaseUrl + "/districts", "", httpOptions)
      .toPromise();
  }
  getRoles() {
    return new Promise((resolve, reject) => {
      resolve(roles);
    });
  }
  saveUtils(vo, type) {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
        type: type,
      }),
    };

    return this.http
      .post<any>(this.utilsBaseUrl + "/add/" + type, vo, httpOptions)
      .toPromise();
  }
  deleteUtils(vo, type) {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
        type: type,
      }),
    };

    return this.http
      .post<any>(this.utilsBaseUrl + "/delete/" + type, vo, httpOptions)
      .toPromise();
  }
  getUtils(type) {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
        type: type,
      }),
    };

    return this.http
      .post<any>(this.utilsBaseUrl + "/get/" + type, "", httpOptions)
      .toPromise();
  }

  getMasterMenuList() {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };

    return this.http
      .post<any>(this.utilsBaseUrl + "/master-menu-list", "", httpOptions)
      .toPromise();
  }
  /* TODO Move to venue service */
  getUsersByUnitId(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
        unitid: id,
      }),
    };

    return this.http
      .post<any>(this.userbaseUrl + "/getbyunitid/" + id, "", httpOptions)
      .toPromise();
  }
  getSubUnitsByUnitId(type, id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
        type: type,
        unitid: id,
      }),
    };

    return this.http
      .post<any>(
        this.utilsBaseUrl + "/getbyunitid/" + type + "/" + id,
        "",
        httpOptions
      )
      .toPromise();
  }
  getUnitsByType(type) {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
        type: type,
      }),
    };

    return this.http
      .post<any>(this.utilsBaseUrl + "/getunits/" + type, "", httpOptions)
      .toPromise();
  }
}
