import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, MatPaginatorModule } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { ManagementService } from "app/services/management.service";
import { UtilsService } from "app/services/utils.service";
import { common } from "app/utils/common";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-incharge-management",
  templateUrl: "./incharge-management.component.html",
  styleUrls: ["./incharge-management.component.css"],
})
export class InchargeManagementComponent implements OnInit, AfterViewInit {
  pageSize;
  length = 10;
  @ViewChild(MatPaginator, {static: false})
  set paginator(value: MatPaginator) {
    if (this.dataSource){
      this.dataSource.paginator = value;
    }
  }
  designation:any;
  dataSource: any;
  searchForm: FormGroup;
  displayedColumns: string[] = [
    "unitid",
    "employeename",
    "email",
    "mobileno",
    "designation",
    "enabled",
    "action",
  ];
  units: any;
  designations: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: ManagementService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private utils: UtilsService,
    private formBuilder: FormBuilder,private cdr: ChangeDetectorRef,
  ) { }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.getUtils();
    this.initilaizeForm();
    this.service.getUsers({ showEnabledOnly: true }).then((res) => {
      this.dataSource = new MatTableDataSource(res);
      this.cdr.detectChanges();
    });
  }
  add() {
    if (this) this.router.navigate(["add-user"], { relativeTo: this.route });
  }
  initilaizeForm(): any {
    this.searchForm = this.formBuilder.group({
      showEnabledOnly: [""],
      employeename: [""],
      email: ["", Validators.pattern(common.emailPattern)],
      mobileno: ["", Validators.pattern(common.phonePattern)],
    });
  }
  reset() {
    this.searchForm.reset();
  }
  get f() {
    return this.searchForm.controls;
  }
  edit(element) {

    this.router.navigate(["edit-user", element.id], {
      relativeTo: this.route,
    });
  }
  delete(element) {
    if (confirm("Are you sure you want to delete this user?")) {
      element.enabled = false;
      this.service.updateUser(element).then(
        (res) => {
          this.toastr.success("Incharge successfuly deleted");
          this.ngOnInit();
        },
        (err) => {
          this.toastr.error(err);
        }
      );
    }
  }
  resetDashboardPassword(element) {
    if (
      confirm(
        "Are you sure you want to reset Dashboard login password for this user?"
      )
    ) {
    }
  }
  resetAppPassword(element) {
    if (
      confirm(
        "Are you sure you want to reset App login password for this user?"
      )
    ) {
    }
  }
  getUtils() {
    this.utils.getUtils("units").then((res) => {
      this.units = res;
    });
    this.utils.getDesignation().then(res => {
      this.designations = res;
    });
  
  }
  getUnitName(id) {
    const o = this.units.find((x) => x.id === id);
    return o.name;
  }
  getDesignationName(id) {
    if(id){
      const o = this.designations.find(x => x.id === id);
      return o.name;
    }
    return 'NA';
    
  }
  getPaginatorData(event) {

  }
  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
    this.dataSource.paginator = this.paginator;
  }
 

}
