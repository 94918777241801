import { ManagementService } from './../../services/management.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent implements OnInit {
  hide: any;
  resetPasswordForm: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private service: ManagementService,
    public dialogRef: MatDialogRef<ResetPasswordComponent>
  ) {
    this.resetPasswordForm = this.formBuilder.group({
      password: ['', Validators.required],
    });
  }

  ngOnInit(): void {}
  onSubmit() {
    if (this.resetPasswordForm.invalid) {
      this.toastr.error('Please enter paswword');
      return;
    }
    this.service
      .resetPassword({
        mobileno: this.data.mobileno,
        password: this.f.password.value,
      })
      .then(
        (res) => {
          if (res) {
            this.toastr.success('Successfully updated password');
            this.dialogRef.close();
          } else {
            this.toastr.error('Password updation failed');
          }
        },
        (err) => {
          this.toastr.error('Password updation failed.');
        }
      );
  }
  get f() {
    return this.resetPasswordForm.controls;
  }
}
