import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class GraphService {
  graphUrl = environment.base_url + "secured/graph";
  constructor(private http: HttpClient, private router: Router) {}
  getActiveEvents() {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };

    return this.http
      .get<any>(this.graphUrl + "/activeevents", httpOptions)
      .toPromise();
  }

  getActiveVenues() {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };

    return this.http
      .get<any>(this.graphUrl + "/activevenues", httpOptions)
      .toPromise();
  }
  getActiveBandobust() {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };

    return this.http
      .get<any>(this.graphUrl + "/activebandobusts", httpOptions)
      .toPromise();
  }
  getRegisteredDevices() {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };

    return this.http
      .get<any>(this.graphUrl + "/registereddevices", httpOptions)
      .toPromise();
  }
  eventCountsByDistrict() {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };

    return this.http
      .post<any>(this.graphUrl + "/eventCountsByDistrict", "", httpOptions)
      .toPromise();
  }
  venueCountsByDistrict() {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };

    return this.http
      .post<any>(this.graphUrl + "/venueCountsByDistrict", "", httpOptions)
      .toPromise();
  }
  checkPointsByDistrict() {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };

    return this.http
      .post<any>(this.graphUrl + "/checkPointsByDistrict", "", httpOptions)
      .toPromise();
  }
}
