import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class VenueService {
  adminBaseurl = environment.base_url + "secured/admin";
  authBaseUrl = environment.base_url + "utils/";
  utilsBaseUrl = environment.base_url + "secured/utils";
  eventsBaseUrl = environment.base_url + "secured/event";
  jsonserverUrl = "http://localhost:3000";
  venueBaseUrl = environment.base_url + "secured/venue";
  constructor(private http: HttpClient, private router: Router) {}
  addVenues(data, id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };

    return this.http
      .post<any>(this.venueBaseUrl + "/add/" + id, data, httpOptions)
      .toPromise();
  }
  getVenuesByFilters(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };
    return this.http
      .post<any>(this.venueBaseUrl + "/getvenuebyfilters", data, httpOptions)
      .toPromise();
  }
  getAllVenues() {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };

    return this.http
      .post<any>(this.venueBaseUrl + "/getall", httpOptions)
      .toPromise();
  }

  getVenueById(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };

    return this.http
      .post<any>(this.venueBaseUrl + "/venue/" + id, httpOptions)
      .toPromise();
  }
  getVenueByEventId(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
        venueid: id,
      }),
    };

    return this.http
      .post<any>(this.venueBaseUrl + "/getbyeventid/" + id, httpOptions)
      .toPromise();
  }
}
