import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class BandobustService {
  adminBaseurl = environment.base_url + "secured/admin";
  authBaseUrl = environment.base_url + "utils/";
  utilsBaseUrl = environment.base_url + "secured/utils";
  eventsBaseUrl = environment.base_url + "secured/event";
  jsonserverUrl = "http://localhost:3000";
  venueBaseUrl = environment.base_url + "secured/venue";
  bandobustBaseUrl = environment.base_url + "secured/bandobust";
  appBaseUrl = environment.base_url + "secured/appadmin";
  bandobustBaseUrlApp = environment.base_url + "secured/app";
  constructor(private http: HttpClient, private router: Router) {}
  addBandobusts(data, id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };

    return this.http
      .post<any>(this.bandobustBaseUrl + "/add/" + id, data, httpOptions)
      .toPromise();
  }

  getActiveBandobustList() {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };

    return this.http
      .post<any>(
        this.bandobustBaseUrl + "/listactivebandobust",
        "",
        httpOptions
      )
      .toPromise();
  }
  getBandobustsByVenueid(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
        venueid: id,
      }),
    };

    return this.http
      .post<any>(this.bandobustBaseUrl + "/venue/" + id, "", httpOptions)
      .toPromise();
  }
  getActiveBandobustsByVenueid(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
        venueid: id,
      }),
    };

    return this.http
      .post<any>(this.bandobustBaseUrl + "/activebandobustbyvenue/" + id, "", httpOptions)
      .toPromise();
  }
  getAllVenues() {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };

    return this.http
      .post<any>(this.venueBaseUrl + "/getall", httpOptions)
      .toPromise();
  }
  getVenueById(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };

    return this.http.post<any>(this.venueBaseUrl + "/venue/" + id, httpOptions).toPromise();
  }
  getAppUsersByDistrict(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
        districtid: id,
      }),
    };

    return this.http
      .post<any>(this.appBaseUrl + "/search/user/" + id, httpOptions)
      .toPromise();
  }
  getActiveBandobustListForApp() {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };

    return this.http
      .get<any>(
        this.bandobustBaseUrlApp + "/listactivebandobust/456",

        httpOptions
      )
      .toPromise();
  }
}
