import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { environment } from "environments/environment";
import jwtDecode, { JwtPayload } from "jwt-decode";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  authBaseUrl = environment.base_url + "auth/";

  constructor(
    private http: HttpClient,
    private router: Router,
    public afAuth: AngularFireAuth,
    public jwtHelper: JwtHelperService
  ) {}

  login(data: any): any {
    const credentials = window.btoa(`${data.mobileno}:${data.password}`);
    return this.http
      .post<any>(
        this.authBaseUrl + "login/dashboard",
        {},
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Basic " + credentials,
          }),
        }
      )
      .toPromise();
  }
  loginApp(data: any): any {
    const credentials = window.btoa(`${data.mobileno}:${data.password}`);
    return this.http
      .post<any>(
        this.authBaseUrl + "login/app",
        {},
        {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            Authorization: "Basic " + credentials,
          }),
        }
      )
      .toPromise();
  }
  changePassword(data: any) {
    return this.http
      .post<any>(this.authBaseUrl + "changePassword", data, {
        headers: new HttpHeaders({
          Accept: "*/*",
        }),
      })
      .toPromise();
  }

  logout(): any {
    localStorage.removeItem("bandobust-token");
    localStorage.removeItem("bandobust-menu");
    localStorage.removeItem("firebaseEmail");
    localStorage.removeItem("firebasePassword");
    localStorage.removeItem("districtId");
    this.afAuth.signOut();
    this.router.navigate(["/"]);
  }

  getUserDetails(): any {
    const user = jwtDecode<JwtPayload>(localStorage.getItem("bandobust-token"));
    /* const user = jwt_decode(localStorage.getItem('token')); */
    return user;
  }

  setToken(token, firebaseEmail, firebasePassword, districtId): any {
    localStorage.setItem("bandobust-token", token);
    localStorage.setItem("firebaseEmail", firebaseEmail);
    localStorage.setItem("firebasePassword", firebasePassword);
    localStorage.setItem("districtId", districtId);
  }

  getToken(): any {
    return localStorage.getItem("bandobust-token");
  }
  getFireBaseEmail() {
    return localStorage.getItem("firebaseEmail");
  }
  getFireBasePassword() {
    return localStorage.getItem("firebasePassword");
  }
  getDistrictId() {
    return localStorage.getItem("districtId");
  }

  isLoggedIn(): any {
    if (this.getToken()) {
      return true;
    }

    return false;
  }
  public isAuthenticated(): boolean {
    // Check whether the token is expired and return
    // true or false
    return !this.jwtHelper.isTokenExpired(this.getToken());
  }
}
