import { EventDetailsComponent } from "./event-details/event-details.component";

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { EventDetailsListComponent } from "./event-details-list/event-details-list.component";

import { VenueAllocationComponent } from "./venue-allocation/venue-allocation.component";
import { BandobustListComponent } from "./bandobust-list/bandobust-list.component";
import { BandobustComponent } from "./bandobust/bandobust.component";

const routes: Routes = [
  {
    path: "",
    component: EventDetailsListComponent,
  },
  {
    path: "event",
    component: EventDetailsComponent,
  },
  {
    path: "event/:id",
    component: EventDetailsComponent,
  },
  {
    path: "venue-allocation",
    component: VenueAllocationComponent,
  },

  {
    path: "bandobust",
    component: BandobustListComponent,

    data: {
      title: "Venues",
    },
  },
  {
    path: "bandobust/:id",
    component: BandobustListComponent,

    data: {
      title: "Venues",
    },
  },
  {
    path: "bandobust-plan",
    component: BandobustComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EventManagementRoutingModule {}
