import { Pipe, PipeTransform } from "@angular/core";
import { AuthService } from "app/services/auth.service";

@Pipe({
  name: "districtpipe",
  pure: false,
})
export class DistrictPipe implements PipeTransform {
  constructor(private auth: AuthService) {}
  transform(items: any[]): any {
    if (!items) {
      return items;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    if (this.auth.getDistrictId() !== "null") {
      return items.filter((item) => item.id == this.auth.getDistrictId());
    } else {
      return items;
    }
  }
}
