import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {


  dashBaseUrl = environment.base_url + "secured/users"
  constructor(private http: HttpClient, private router: Router) { }

  countAllUsers() {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };

    return this.http
      .post<any>(this.dashBaseUrl + "/search/app/countall", '', httpOptions)
      .toPromise();
  }
  countPS() {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };

    return this.http
      .post<any>(this.dashBaseUrl + "/search/app/countps", '', httpOptions)
      .toPromise();
  }
  countDEF() {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };

    return this.http
      .post<any>(this.dashBaseUrl + "/search/app/countdef", '', httpOptions)
      .toPromise();
  }
  countNonDEF() {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };

    return this.http
      .post<any>(this.dashBaseUrl + "/search/app/countnondef", '', httpOptions)
      .toPromise();
  }
}
