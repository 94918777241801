import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ManagementService } from "app/services/management.service";
import { UtilsService } from "app/services/utils.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-menu-selector",
  templateUrl: "./menu-selector.component.html",
  styleUrls: ["./menu-selector.component.css"],
})
export class MenuSelectorComponent implements OnInit {
  form: FormGroup;
  menuList: any;
  constructor(
    private service: ManagementService,
    private utils: UtilsService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<MenuSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(this.data);
  }

  ngOnInit() {
    this.getUtils();
  }
  getUtils() {
    this.utils
      .getMasterMenuList()
      .then((res) => {
        this.menuList = res;
      })
      .finally(() => {
        this.initilaizeForm();
      })
      .finally(async () => {
        const val = await this.service.listUserMenus(this.data.id);      
        val.forEach((element) => {
          const controlname = (element.menuId).toString();
          console.log(controlname)
          this.form.controls[controlname].setValue(true);
        });
      });
  }
  initilaizeForm(): any {
    this.form = this.formBuilder.group({});
    this.menuList.forEach((element) => {
      this.form.addControl(element.id, new FormControl(false));
    });
  }
  get f() {
    return this.form.controls;
  }
  onSubmit() {
    const o = this.form.getRawValue();
    const menus = [];
    for (var key of Object.keys(o)) {
      if (o[key]) {
        menus.push(key);
      }
    }
    this.service.updateUserMenus(menus, this.data.id).then(
      (res) => {
        this.toastr.success("Sucessfully updated access");
        this.dialogRef.close();
      },
      (err) => {
        this.toastr.error(err.message);
      }
    );
  }
}
