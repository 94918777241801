import { MatPasswordStrengthModule } from "@angular-material-extensions/password-strength";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AngularMaterialModule } from "app/angular-material/angular-material.module";
import { EventManagementModule } from "app/event-management/event-management.module";
import { LocationSelectorComponent } from "app/event-management/helpers/location-selector/location-selector.component";
import { AppUserComponent } from "./app-user/app-user.component";
import { InchargeManagementComponent } from "./incharge-management/incharge-management.component";
import { InchargeComponent } from "./incharge/incharge.component";
import { ManagementRoutingModule } from "./management-routing.module";
import { ManagementComponent } from "./management/management.component";
import { MenuManagementComponent } from './menu-management/menu-management.component';
import { MenuSelectorComponent } from './menu-selector/menu-selector.component';





@NgModule({
  declarations: [
    ManagementComponent,
    AppUserComponent,
    InchargeComponent,
    InchargeManagementComponent,
    MenuManagementComponent,
    MenuSelectorComponent,   
    
  ],
  imports: [
    CommonModule,
    ManagementRoutingModule,
    AngularMaterialModule,
    MatPasswordStrengthModule,
    EventManagementModule
  ],
})
export class ManagementModule {}
