import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "./../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class MenuService {
  adminBaseurl = environment.base_url + "secured/admin";
  userBaseurl = environment.base_url + "auth/menu";
  menuBaseUrl = environment.base_url + "secured/menu";

  constructor(private http: HttpClient) {}

  getAllMenuListing(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };
    return this.http
      .post<any>(this.adminBaseurl + "/menu/search/all", "", httpOptions)
      .toPromise();
  }
  getExisitingMenuMapping(userid: any): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };
    return this.http
      .post<any>(
        this.adminBaseurl + "/menu/search/user/" + userid,
        "",
        httpOptions
      )
      .toPromise();
  }
  udpateMenuMapping(obj: any, id: number): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };
    return this.http
      .post<any>(
        this.adminBaseurl + "/menu/user/update/" + id,
        obj,
        httpOptions
      )
      .toPromise();
  }

  getUserMenu(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };
    return this.http
      .post<any>(this.menuBaseUrl + "/list-user-menu", "", httpOptions)
      .toPromise();
  }

  setUserMenu(menu): void {
    localStorage.setItem("bandobust-menu", JSON.stringify(menu));
  }

  showUserMenu(): any {
    if (localStorage.getItem("bandobust-menu")) {
      return JSON.parse(localStorage.getItem("bandobust-menu"));
    } else {
      return [];
    }
  }
}
