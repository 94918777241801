import { MapsAPILoader } from "@agm/core";
import {
  NgxMatDateFormats,
  NGX_MAT_DATE_FORMATS,
} from "@angular-material-components/datetime-picker";
import { NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular-material-components/moment-adapter";
import { Component, Input, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormArray,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { MAT_DATE_LOCALE, ThemePalette } from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DateValidators } from "app/pipes/DateValidator";
import { UtilsService } from "app/services/utils.service";
import * as moment from "moment";
const CUSTOM_MOMENT_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: "L, LT",
  },
  display: {
    dateInput: "DD/MM/YYYY HH:mm",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};
declare let google: any;
@Component({
  selector: "app-bandobust-selector",
  templateUrl: "./bandobust-selector.component.html",
  styleUrls: ["./bandobust-selector.component.css"],

  providers: [
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_MOMENT_FORMATS },
    {
      provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: { useUtc: true },
    },
  ],
})
export class BandobustSelectorComponent implements OnInit {
  @Input() units: any;
  public disableMinute = true;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = true;
  public enableMeridian = true;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 15;
  public stepSecond = 1;
  public color: ThemePalette = "primary";
  public defaultTime = [new Date().getHours, 0 , 0]
  public defaultTime2 = [new Date().getHours, 15 , 0]
  public defaultTime3 = [new Date().getHours, 30 , 0]
  edit = false;
  form: any;
  adjustStart: boolean;

  constructor(
    private _snackBar: MatSnackBar,
    private utils: UtilsService,

    private dailog: MatDialog,
    private formBuilder: FormBuilder,
    private apiloader: MapsAPILoader
  ) {}

  ngOnInit(): void {
    this.initializeForm();
  }
  initializeForm(): void {
    this.form = this.formBuilder.group({
      locations: new FormArray([]),
    });
  }
  initializeFormControls(lat, lng): any {
    return new FormGroup(
      {
        id: new FormControl(null),
        lat: new FormControl(lat, Validators.required),
        lng: new FormControl(lng, Validators.required),
        appuser: new FormControl(null, Validators.required),
        headcount: new FormControl(null, Validators.required),
        venueid: new FormControl(null),
        reportingtime: new FormControl(null, Validators.required),
        starttime: new FormControl(null, Validators.required),
        endtime: new FormControl(null, Validators.required),
        locationname: new FormControl(null, Validators.required),
      },
      {
        validators: Validators.compose([
          DateValidators.dateLessThan("reportingtime", "starttime", {
            reportingtime: true,
          }),
          DateValidators.dateLessThan("starttime", "endtime", {
            reportingtime: true,
          }),
        ]),
      }
    );
  }
  get f(): any {
    return this.form.controls;
  }
  addLocation(lat, lng): void {
    /*  if (this.form.invalid) {
      return;
    } */

    this.f.locations.push(this.initializeFormControls(lat, lng));
    this.f.locations.updateValueAndValidity();
    this.setLocationName(lat, lng, this.f.locations.length - 1);
  }
  removeLocation(i): void {
    this.f.locations.removeAt(i);
    this.f.locations.updateValueAndValidity();
    /*  ) */
  }
  updateMaxHeadCount($event, i) {
    const o = this.units.find((x) => x.id === $event.value);
    
    this.f.locations.controls[i]
      .get("headcount")
      .setValidators([Validators.max(o.headcount)]);
  }
  setLocationName(lat, lng, i) {
    let geocoder = new google.maps.Geocoder();
    let latlng = new google.maps.LatLng(lat, lng);
    let request = {
      latLng: latlng,
    };
    geocoder.geocode(request, (results, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        if (results[0] != null) {
          this.f.locations.controls[i]
            .get("locationname")
            .setValue(results[0].formatted_address);
          this.f.locations.updateValueAndValidity;
        }
      }
    });
  }
  addLocationFromApi(o): void {
    this.edit = true;
    this.f.locations.push(this.initializeFormControlsFromApi(o));
    this.f.locations.updateValueAndValidity();
  }
  initializeFormControlsFromApi(o): any {
    return new FormGroup({
      id: new FormControl(o.id),
      lat: new FormControl(o.lat, Validators.required),
      lng: new FormControl(o.lng, Validators.required),
      appuser: new FormControl(o.appuser, Validators.required),
      headcount: new FormControl(o.headcount, Validators.required),
      venueid: new FormControl(o.venueid),
      reportingtime: new FormControl(
        moment(o.reportingtime),
        Validators.required
      ),
      starttime: new FormControl(moment(o.starttime), Validators.required),
      endtime: new FormControl(moment(o.endtime), Validators.required),
      locationname: new FormControl(o.locationname, Validators.required),
    });
  }
  adjustStartTime(event: any) {
    let me = this;

    setTimeout(() => {
      if (event.target.value == "12") {
        me.adjustStart = true;
      }
      else {
        me.adjustStart = false;
      }
    }, 50);
  }
}
