import { Component, OnInit } from "@angular/core";
import { AngularFirestore, AngularFirestoreCollection } from "@angular/fire/firestore";
import { BandobustService } from "app/services/bandobust.service";
import { DashboardService } from "app/services/dashboard.service";
import { GraphService } from "app/services/graph.service";
import * as Chartist from "chartist";
import { map } from "rxjs/operators";
@Component({
  selector: "app-main",
  templateUrl: "./main.component.html",
  styleUrls: ["./main.component.css"],
})
export class MainComponent implements OnInit {
  locationsCollection: any;
  locations: any;
  totalUsers: any;
  totalPS: any;
  totalDef: any;
  totalNonDef: any;
  location: any;
  constructor(private service: DashboardService, private afs: AngularFirestore) { }

  ngOnInit() {
    this.service.countAllUsers().then(res => {
      this.totalUsers = res;
    });
    this.service.countPS().then(res => {
      this.totalPS = res;
    });
    this.service.countDEF().then(res => {
      this.totalDef = res;
    });
    this.service.countNonDEF().then(res => {
      this.totalNonDef = res;
    });
    this.liveGPS();
  }
  liveGPS() {
    this.locationsCollection = this.afs.collection(
      `locations`,
      (ref) => ref.orderBy("timestamp",'desc').limit(10)
    );
    this.locations = this.locationsCollection.snapshotChanges().pipe(
      map((actions: any) => {
        return actions.map((a) => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
    this.locations.subscribe((location) => {
      this.location = location;
      console.log(this.location)
     
    });
  }
}
