import { Component, Input, OnInit, Output } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormArray,
  FormControl,
  Validators,
  FormGroupName,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UtilsService } from "app/services/utils.service";
import { EventEmitter } from '@angular/core';

@Component({
  selector: "app-vehicle-selector",
  templateUrl: "./vehicle-selector.component.html",
  styleUrls: ["./vehicle-selector.component.css"],
})
export class VehicleSelectorComponent implements OnInit {
  @Output() notifyVehicleValues: EventEmitter<any>= new EventEmitter();
  form: FormGroup;
  constructor(
    private _snackBar: MatSnackBar,
    private utils: UtilsService,

    private dailog: MatDialog,
    private formBuilder: FormBuilder
  ) {
 
  }

  ngOnInit(): void {
    this.initializeForm();
    this.form.valueChanges.subscribe(res=>{
      
      this.notifyVehicleValues.emit(res);
    })
  }

  initializeForm(): void {
    this.form = this.formBuilder.group({
      vehicles: new FormArray([this.initializeFormControls()]),
    });
  }
  initializeFormControls(): any {
    return new FormGroup({
      id: new FormControl(null),
      vehicle: new FormControl(null, Validators.required),
      count: new FormControl(null, Validators.required),
    });
  }
  get f(): any {
    return this.form.controls;
  }
  addVehicle(): void {
    if (this.form.invalid) {
      return;
    }
    this.f.vehicles.push(this.initializeFormControls());
    this.f.vehicles.updateValueAndValidity();
  }
  removeVehicle(i): void {
    this.f.vehicles.removeAt(i);
    this.f.vehicles.updateValueAndValidity();
  }
}
