import { Component, OnInit } from "@angular/core";
import { AuthService } from "app/services/auth.service";
import { MenuService } from "./../../services/menu.service";

declare const $: any;
declare interface RouteInfo {
  menu_url: string;
  menu_name: string;
  icon: string;
  icon_class: string;
}

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor(public auth: AuthService, public menu: MenuService) {}

  ngOnInit() {
 
  }
  isMobileMenu() {
    if ($(window).width() > 996) {
      return false;
    }
    return true;
  }
}
