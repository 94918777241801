import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  eventdetails: any;
  eventmarkers: any;
  constructor() {}
}
