import { Component, OnInit } from "@angular/core";
import { single } from "app/pipes/single";
import { GraphService } from "app/services/graph.service";

@Component({
  selector: "app-barchart",
  templateUrl: "./barchart.component.html",
  styleUrls: ["./barchart.component.css"],
})
export class BarchartComponent implements OnInit {
  single: any[];
  // options
  gradient: boolean = true;
  showLegend: boolean = false;
  showLabels: boolean = true;
  isDoughnut: boolean = true;
  legendPosition: string = "below";

  showXAxisLabel;
  showYAxisLabel;
  xAxisLabel;
  yAxisLabel;
  showXAxis = true;
  showYAxis;
  data: any;
  constructor(private service: GraphService) {}
  ngOnInit(): void {
    this.service.eventCountsByDistrict().then((res) => {
      this.data = res;
    });
  }

  onSelect(data): void {
    
  }

  onActivate(data): void {
   
  }

  onDeactivate(data): void {
    
  }
}
