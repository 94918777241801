import { EventService } from "./../service/event.service";
import { NgModel } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  NgZone,
  OnInit,
  Output,
} from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
declare let google: any;
@Component({
  selector: "app-location-selector",
  templateUrl: "./location-selector.component.html",
  styleUrls: ["./location-selector.component.css"],
})
export class LocationSelectorComponent implements OnInit, AfterViewInit {
  pageSize;
  length;
  lat = 25.67;
  lng = 94.12;
  in;
  zoom = 15;
  agmMarkers = [];

  constructor(
    public dialogRef: MatDialogRef<LocationSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ngZone: NgZone
  ) { }
  ngAfterViewInit(): void {
    this.getLatLngFromAddress(this.data.district);
    if (this.data.latitude && this.data.longitude) {
      this.lat = this.data.latitude;
      this.lng = this.data.longitude;
      this.agmMarkers = [];
      this.agmMarkers.push({
        lat: this.data.latitude,
        lng: this.data.longitude
      });
    }
  }
  ngOnInit(): void { }
  addMarker(event) {
    this.agmMarkers = [];
    this.agmMarkers.push({
      lat: event.coords.lat,
      lng: event.coords.lng,
    });
  }
  removeMarker(marker) {
    this.agmMarkers = this.agmMarkers.filter((obj) => obj !== marker);
  }
  getIndex(i) {
    return (this.agmMarkers.findIndex((x) => x === i) + 1).toString();
  }
  markerDragEnd(i, event) {
    this.agmMarkers[i - 1] = {
      lat: event.coords.lat,
      lng: event.coords.lng,
    };
  }
  resetMarkers() {
    if (
      confirm("Are you sure to reset markers? This cannot be reverted back.")
    ) {
      this.agmMarkers = [];
    }
    return;
  }
  onSubmit() {
    this.dialogRef.close(this.agmMarkers[0]);
  }
  getLatLngFromAddress(district) {
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: district }).then((res) => {
      this.lat = res.results[0].geometry.location.lat();
      this.lng = res.results[0].geometry.location.lng();
    });
  }
  getCurrentLocation() { }
  onAutocompleteSelected(e) { }
  onLocationSelected(location) {
    this.agmMarkers.push({
      lat: location.latitude,
      lng: location.longitude,
    });
    this.lat = location.latitude;
    this.lng = location.longitude;
  }
}
