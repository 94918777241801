import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UtilsService } from "app/services/utils.service";

@Component({
  selector: "app-non-def-selector",
  templateUrl: "./non-def-selector.component.html",
  styleUrls: ["./non-def-selector.component.css"],
})
export class NonDefSelectorComponent implements OnInit {
  @Output() notifyNonDefValues: EventEmitter<any> = new EventEmitter();
  form: FormGroup;
  units: any;
  constructor(
    private _snackBar: MatSnackBar,
    private utils: UtilsService,

    private dailog: MatDialog,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.getUtils();
    this.initializeForm();
    this.form.valueChanges.subscribe((res) => {
      this.notifyNonDefValues.emit(res);
    });
  }

  initializeForm(): void {
    this.form = this.formBuilder.group({
      nondefunits: new FormArray([this.initializeFormControls()]),
    });
  }
  initializeFormControls(): any {
    return new FormGroup({
      id: new FormControl(null),
      unitid: new FormControl(null, Validators.required),
      count: new FormControl(null, Validators.required),
      status: new FormControl("pending"),
    });
  }
  get f(): any {
    return this.form.controls;
  }
  addUnit(): void {
    if (this.form.invalid) {
      return;
    }
    this.f.nondefunits.push(this.initializeFormControls());
    this.f.nondefunits.updateValueAndValidity();
  }
  removeUnit(i): void {
    this.f.nondefunits.removeAt(i);
    this.f.nondefunits.updateValueAndValidity();
  }
  getUtils() {
    this.utils.getUnitsByType("NON-DEF").then((res) => {
      this.units = res;
    });
  }
  updateMaxHeadCount($event, i) {
    const o = this.units.find((x) => x.id === $event.value);
    
    this.f.nondefunits.controls[i]
      .get("count")
      .setValidators([Validators.max(o.headcount)]);
  }
}
