import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class EventManageService {
  adminBaseurl = environment.base_url + "secured/admin";
  authBaseUrl = environment.base_url + "utils/";
  utilsBaseUrl = environment.base_url + "secured/utils";
  eventsBaseUrl = environment.base_url + "secured/event";
  jsonserverUrl = "http://localhost:3000";
  constructor(private http: HttpClient, private router: Router) {}
  addEvent(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };

    return this.http
      .post<any>(this.eventsBaseUrl + "/add", data, httpOptions)
      .toPromise();
  }
  updateEvent(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };

    return this.http
      .post<any>(this.eventsBaseUrl + "/update", data, httpOptions)
      .toPromise();
  }
  getEvents() {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };

    return this.http
      .post<any>(this.eventsBaseUrl + "/getall", httpOptions)
      .toPromise();
  }
  getEventsByFilter(data) {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };

    return this.http
      .post<any>(this.eventsBaseUrl + "/geteventbyfilters", data, httpOptions)
      .toPromise();
  }
  getEventbyId(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };

    return this.http
      .post<any>(this.eventsBaseUrl + "/event/" + id, httpOptions)
      .toPromise();
  }
}
