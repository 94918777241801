import { Appearance } from '@angular-material-extensions/google-maps-autocomplete';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UtilsService } from 'app/services/utils.service';
/// <reference types="@types/googlemaps" />
@Component({
  selector: 'app-places-complete',
  templateUrl: './places-complete.component.html',
  styleUrls: ['./places-complete.component.css']
})
export class PlacesCompleteComponent implements OnInit {
  @Output() outputCordinates: EventEmitter<any> = new EventEmitter();
  utilList: any;
  public appearance = Appearance;
  constructor(private utilsService: UtilsService) {}
  ngOnInit(): void {
   
  }
  location = new FormControl();
  options: string[] = ["One", "Two", "Three"];
  onLocationSelected(location) {
    
  }

  onAutocompleteSelected(result) {
    
  }
}
