import { ComponentsModule } from './../components/components.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AngularMaterialModule } from 'app/angular-material/angular-material.module';

import { MainComponent } from './main/main.component';
import { ManagementModule } from 'app/management/management.module';
import { PiechartComponent } from './charts/piechart/piechart.component';
import { BarchartComponent } from './charts/barchart/barchart.component';
import { LinechartComponent } from './charts/linechart/linechart.component';
import { DateAgoPipe } from 'app/pipes/date-ago.pipe';


@NgModule({
  declarations: [DashboardComponent, MainComponent, PiechartComponent, BarchartComponent, LinechartComponent,DateAgoPipe],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    AngularMaterialModule,
    ComponentsModule,
    ManagementModule
  ],
 
})
export class DashboardModule {}
