import { EventEmitter } from "@angular/core";
import { Component, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "app/services/auth.service";
import { UtilsService } from "app/services/utils.service";

import { ToastrService } from "ngx-toastr";
import { EventFilterVO } from "../filter-models/event-filter-vo";

@Component({
  selector: "app-event-filter",
  templateUrl: "./event-filter.component.html",
  styleUrls: ["./event-filter.component.css"],
})
export class EventFilterComponent implements OnInit {
  searchForm: FormGroup;
  @Output() output = new EventEmitter<EventFilterVO>();
  eventtypes: any;
  districts: any;
  constructor(
    private utils: UtilsService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    this.getUtils();
    this.initilaizeForm();
  }
  initilaizeForm(): any {
    this.searchForm = this.formBuilder.group({
      ismultidayevent: [null],
      districtid: [null, Validators.required],
      eventtype: [null],
      isstateordistrict: [null],
    });
  }
  onSubmit() {
    if (this.searchForm.invalid) {
      this.toastr.warning("Select some filters");
      return;
    }
    const o = this.searchForm.getRawValue();
    const vo = new EventFilterVO();
    vo.ismultidayevent = o.ismultidayevent;
    vo.districtid = o.districtid;
    vo.eventtype = o.eventtype;
    vo.isstateordistrict = o.isstateordistrict;
    vo.enddate = new Date();
    this.output.emit(vo);
  }
  reset() {
    this.searchForm.reset();
  }
  getUtils() {
    this.utils.getEventTypes().then((res) => {
      this.eventtypes = res;
    });

    this.utils.getDistricts().then((res) => {
      this.districts = res;
    });
  }
}
