import { AuthService } from './../../services/auth.service';

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent implements OnInit {
  hide1 = true;
  hide2 = true;
  changePasswordForm: FormGroup;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private auth: AuthService
  ) {}
  ngOnInit(): void {
    this.changePasswordForm = this.formBuilder.group({
      /* email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(common.emailPattern),
        ]),
      ], */
      oldpassword: ['', [Validators.required]],
      newpassword: ['', [Validators.required]],
    });
  }
  updatePassword(): void {
    if (this.changePasswordForm.invalid) {
      this.toastr.warning('Invalid details supplied');
      return;
    }
    this.auth.changePassword(this.changePasswordForm.getRawValue()).then(
      (res) => {
        this.toastr.success('Password successfully updated');
        this.changePasswordForm.reset();
      },
      (err) => {
        
        this.toastr.error(err);
      }
    );
  }
}
