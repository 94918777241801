export const environment = {
  production: false,
 /*  base_url: "http://localhost:8080/cug-police-api/", */
base_url: "https://cugapi.bandobust.in/",
  firebaseConfig: {
    apiKey: "AIzaSyBL-Y0NRLLX8yWyZ8a4L1wlYrDX85dUPC0",
    authDomain: "police-citizen-app-4cfd5.firebaseapp.com",
    databaseURL:
      "https://police-citizen-app-4cfd5-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "police-citizen-app-4cfd5",
    storageBucket: "police-citizen-app-4cfd5.appspot.com",
    messagingSenderId: "987951098864",
    appId: "1:987951098864:web:2131ec47647b3dfd1b4b35",
    measurementId: "G-56KMQDEVVX",
  },
};
