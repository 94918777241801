import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "./../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ManagementService {
  private userSubscription$: BehaviorSubject<any> = new BehaviorSubject(null);

  getInchargeObs(): Observable<any> {
    return this.userSubscription$.asObservable();
  }

  setInchargeObs(profile: any) {
    this.userSubscription$.next(profile);
  }
  editUser: any;
  managementUrl = environment.base_url + "management/";
  adminBaseurl = environment.base_url + "secured/admin";

  constructor(private http: HttpClient, private router: Router) {}
  getUsers(data): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };

    return this.http
      .post<any>(this.adminBaseurl + "/search/user", data, httpOptions)
      .toPromise();
  }
  getUserById(id): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
        id: id,
      }),
    };

    return this.http
      .post<any>(this.adminBaseurl + "/search/userbyid/" + id, "", httpOptions)
      .toPromise();
  }
  addUser(user: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };

    return this.http
      .post<any>(this.adminBaseurl + "/add/user", user, httpOptions)
      .toPromise();
  }
  updateUser(user: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };

    return this.http
      .post<any>(this.adminBaseurl + "/update/user", user, httpOptions)
      .toPromise();
  }
  resetPassword(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };

    return this.http
      .post<any>(this.adminBaseurl + "/reset-password", data, httpOptions)
      .toPromise();
  }
  lockUnlockUser(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
      }),
    };

    return this.http
      .post<any>(this.adminBaseurl + "/lock-unlock-user", data, httpOptions)
      .toPromise();
  }
  updateUserMenus(data: any, id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
        id: id,
      }),
    };

    return this.http
      .post<any>(
        this.adminBaseurl + "/update/user-menus/" + id,
        data,
        httpOptions
      )
      .toPromise();
  }
  listUserMenus(id: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: "*/*",
        id: id,
      }),
    };

    return this.http
      .post<any>(
        this.adminBaseurl + "/menu/list-user-menu/" + id,
        "",
        httpOptions
      )
      .toPromise();
  }
}

/*  */
