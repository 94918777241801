import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { UtilsService } from "app/services/utils.service";

import PlaceResult = google.maps.places.PlaceResult;
@Component({
  selector: "app-auto-complete",
  templateUrl: "./auto-complete.component.html",
  styleUrls: ["./auto-complete.component.css"],
})
export class AutoCompleteComponent implements OnInit {
  @Output() outputCordinates: EventEmitter<any> = new EventEmitter();
  utilList: any;
  constructor(private utilsService: UtilsService) {}
  location = new FormControl();
  options: string[] = ["One", "Two", "Three"];
  ngOnInit(): void {
    this.location.valueChanges.subscribe((res) => {
      if (typeof res === "object") {
        this.outputCordinates.emit({ lat: res.lat, lng: res.lng });
      }
    });
    this.getutils();
  }
  onLocationSelected(location: Location) {
    
  }

  onAutocompleteSelected(result: PlaceResult) {
    
  }
  getutils() {
    this.utilsService.getUtils("location").then((res) => {
      this.utilList = res;
    });
  }
  displayFn(location): string {
    return location ? location.name : "";
  }
}
