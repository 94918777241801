import { Component, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { ManagementService } from "app/services/management.service";
import { UtilsService } from "app/services/utils.service";
import { ToastrService } from "ngx-toastr";
import { COMMA, ENTER } from "@angular/cdk/keycodes";

@Component({
  selector: "app-app-user",
  templateUrl: "./app-user.component.html",
  styleUrls: ["./app-user.component.css"],
})
export class AppUserComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  form: FormGroup;
  designation: any;
  subunits: any;
  constructor(
    public fb: FormBuilder,
    private utils: UtilsService,
    private toastr: ToastrService,
    private service: ManagementService
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    this.loadUtils();
    this.appInchargeSubscription();
  }
  initializeForm(): void {
    this.form = this.fb.group({
      appusers: new FormArray([this.initializeAppUserControls()]),
    });
  }
  get f(): any {
    return this.form.controls;
  }
  initializeAppUserControls(): any {
    return new FormGroup({
      id: new FormControl(null),
      deviceid: new FormControl(null, Validators.required),
      employeename: new FormControl(null, Validators.required),
      headcount: new FormControl(null, Validators.required),
      role: new FormControl(null),
      designation: new FormControl(null, Validators.required),
      email: new FormControl(null),
      mobileno: new FormControl(null),
      enabled: new FormControl(null),
      subunitid: new FormControl(null, Validators.required),
      appuserdevices: new FormControl([]),
      password:new FormControl(null)
    });
  }

  addAppUser(): void {
    if (this.form.invalid) {
      return;
    }
    this.f.appusers.push(this.initializeAppUserControls());
    this.f.appusers.updateValueAndValidity();
  }

  removeAppUser(i): void {
    this.f.appusers.removeAt(i);
    this.f.appusers.updateValueAndValidity();
  }
  loadUtils(): void {
    this.utils.getDesignation().then((res) => {
      this.designation = res;
    });
  }
  appInchargeSubscription() {
    this.service.getInchargeObs().subscribe((x) => {
      this.utils.getSubUnitsByUnitId("subunits", x.unitid).then((res) => {
        this.subunits = res;
      });
    });
  }
  iterateOverApi(data) {
    for (let i = 0; i < data.length; i++) {
      if (i > 0) {
        this.f.appusers.push(this.initializeAppUserControls());
      }
      
      this.f.appusers.controls[i].patchValue(data[i]);
      this.f.appusers.updateValueAndValidity();
    }
  }
  add(event, i) {
    const input = event.input;
    const value = event.value;
    if (value === "") {
      return;
    }
    this.f.appusers.controls[i]
      .get("appuserdevices")
      .value.push({ id: null, deviceid: value, maindeviceid: null });
    this.f.appusers.updateValueAndValidity();
    if (input) {
      input.value = "";
    }
  }
  remove(data, i): void {
   const index = this.f.appusers.controls[i]
      .get("appuserdevices")
      .value.indexOf(data);
    if (index >= 0) {
      this.f.appusers.controls[i].get("appuserdevices").value.splice(index, 1);
      this.f.appusers.updateValueAndValidity();
    }
  }
}
