import { TokenInterceptor } from "./interceptors/token.interceptor";

import { HttpInterceptorInterceptor } from "./interceptors/http-interceptor.interceptor";
import { AuthModule } from "./auth/auth.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule } from "@angular/router";

import { AppRoutingModule } from "./app.routing";

import { AppComponent } from "./app.component";

import { AgmCoreModule } from "@agm/core";
import { AngularMaterialModule } from "./angular-material/angular-material.module";
import { DashboardModule } from "./dashboard/dashboard.module";

import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from "ngx-toastr";
import { MatPasswordStrengthModule } from "@angular-material-extensions/password-strength";
import { FileUploadModule } from "@iplab/ngx-file-upload";
import { MatGoogleMapsAutocompleteModule } from "@angular-material-extensions/google-maps-autocomplete";
import {
  NgxMatDateFormats,
  NgxMatNativeDateModule,
  NGX_MAT_DATE_FORMATS,
} from "@angular-material-components/datetime-picker";
import { NgxMatMomentModule } from "@angular-material-components/moment-adapter";
import { ErrorInterceptor } from "./interceptors/error.interceptor";
import { environment } from "environments/environment";
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { JwtModule } from "@auth0/angular-jwt";

// for HttpClient import:
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";

// for Router import:
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";

// for Core import:
import { LoadingBarModule } from "@ngx-loading-bar/core";
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { DistrictPipe } from "./pipes/districtpipe.pipe";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { DateAgoPipe } from './pipes/date-ago.pipe';

export function tokenGetter() {
  return localStorage.getItem("bandobust-token");
}
@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    AppRoutingModule,
    AuthModule,
    DashboardModule,
    NgxMatMomentModule,
    FileUploadModule,
    // for HttpClient use:
    LoadingBarHttpClientModule,

    // for Router use:
    LoadingBarRouterModule,

    // for Core use:
    LoadingBarModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: "toast-top-center",
      preventDuplicates: true,
    }),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyCdgCCvY4Y3h03OvXlswB976vvy33Ec2mg",
      libraries: ["places"],
    }),
    NgxSpinnerModule,
    MatPasswordStrengthModule.forRoot(),
    MatGoogleMapsAutocompleteModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ["*"],
      },
    }),
    AgmCoreModule.forRoot(),
    NgxMaterialTimepickerModule
  ],
  declarations: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
