import { ToastrService } from 'ngx-toastr';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { OtpService } from 'app/services/otp.service';
import { common } from 'app/utils/common';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
})
export class ForgotPasswordComponent implements OnInit {
  otpValidated: any;
  passwordreset: any;
  forgotForm: FormGroup;
  hide = true;
  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private otp: OtpService
  ) { }

  ngOnInit(): void {
    this.setValidations();
    this.forgotForm = this.formBuilder.group({
      mobileno: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(common.phonePattern),
        ]),
      ],
      otp: [''],
    });
  }

  generateOtp() {
    if (this.forgotForm.invalid) {
      this.toastr.error('Phone number is required');
      return;
    }

    this.otp.generateOtp(this.f.mobileno.value, {}).then(
      (res) => {
        this.otpValidated = true;
        this.toastr.success('OTP has been sent to your mobile number');
      },
      (err) => {
        this.toastr.error(err);
      }
    );
  }
  validateOtp() {
    this.otp.resetPassword(this.f.mobileno.value, this.f.otp.value, {}).then(
      (res) => {
        this.toastr.success('Password has been sent to your mobile number');
        this.forgotForm.reset();
        this.setValidations();
      },
      (error) => {
        this.toastr.error(error);
      }
    );
  }

  get f() {
    return this.forgotForm.controls;
  }
  setValidations() {
    this.otpValidated = false;
    this.passwordreset = false;
  }
}
