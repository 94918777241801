import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, MatPaginatorModule } from "@angular/material/paginator";
import { ActivatedRoute, Router } from "@angular/router";
import { EventFilterVO } from "app/filters/filter-models/event-filter-vo";
import { AuthService } from "app/services/auth.service";
import { EventManageService } from "app/services/eventmanage.service";
import { UtilsService } from "app/services/utils.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-event-details-list",
  templateUrl: "./event-details-list.component.html",
  styleUrls: ["./event-details-list.component.css"],
})
export class EventDetailsListComponent implements OnInit {
  pageSize;
  length;
  @ViewChild(MatPaginatorModule) paginator: MatPaginator;
  dataSource: any;
  searchForm: FormGroup;
  displayedColumns: string[] = [
    "id",
    "eventtype",
    "eventname",
    "eventdescription",
    "district",
    "ismultidayevent",
    "isallocated",
    "action",
  ];
  eventtypes: any;
  districts: any;
  apidistricts: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private service: EventManageService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private utils: UtilsService,
    private formBuilder: FormBuilder,
    private auth: AuthService
  ) {
    this.getUtils();
  }

  ngOnInit(): void {}
  add() {
    this.router.navigate(["event"], { relativeTo: this.route });
  }
  getUtils() {
    this.utils.getEventTypes().then((res) => {
      this.eventtypes = res;
    });
   /*  this.service.getEventsByFilter(new EventFilterVO()).then((res) => {
      this.dataSource = res;
    }); */
    this.utils.getDistricts().then((res) => {
      this.districts = res;
    });
  }
  getEventTypename(id) {
    let o = this.eventtypes.find((x) => x.id === id);

    return o.name;
  }
  getSingleDayMultiDay(val) {
    return val ? "Multi day" : "Single day";
  }
  getDistrictNames(element) {
    let list = [];
    for (const o of element) {
      let v = this.districts?.find((x) => x.id === o.districtid);
      list.push(v.name);
    }
    return list;
  }
  allocateCheckpoints(element) {
    
    this.router.navigateByUrl("/dashboard/event/venue-allocation", {
      state: { id: element.id },
    });
  }
  edit(element) {
    this.router.navigate(["event", element.id], {
      relativeTo: this.route,
    });
  }
  renewTable(vo) {
    this.service.getEventsByFilter(vo).then((res) => {
      this.dataSource = res;
    });
  }
  viewVenues(element) {
    this.router.navigate(["/dashboard/event/bandobust"], {
      queryParams: { id: element.id },
    });
  }
}
