import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { LocationSelectorComponent } from "app/event-management/helpers/location-selector/location-selector.component";

import { ManagementService } from "app/services/management.service";
import { UtilsService } from "app/services/utils.service";
import { common } from "app/utils/common";
import { ToastrService } from "ngx-toastr";
import { AppUserComponent } from "../app-user/app-user.component";

@Component({
  selector: "app-incharge",
  templateUrl: "./incharge.component.html",
  styleUrls: ["./incharge.component.css"],
})
export class InchargeComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("appusers", { static: false })
  appusers: AppUserComponent;
  designation: any;
  districts: any;
  userForm: FormGroup;
  units: any;
  editMode = false;
  showfordef = true;
  hide = true;
  id: any;
  subunits: any;
  constructor(
    private utils: UtilsService,
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    public service: ManagementService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private dailog: MatDialog,
  ) {
    this.id = this.route.snapshot.params.id;

  }
  ngAfterViewInit(): void { }
  ngOnDestroy(): void {
    this.service.editUser = null;
  }

  async ngOnInit() {
    this.initilaizeForm();
    this.loadUtils();
    this.listeners();

    if (this.id) {
      this.editMode = true;
      const res = await this.getdata();

      this.userForm.patchValue(res);
      this.userForm.updateValueAndValidity();
    }
  }
  initilaizeForm(): any {
    this.userForm = this.formBuilder.group({
      id: [""],
      unitid: ["", [Validators.required]],
      employeename: ["", [Validators.required]],
      designation: ["", [Validators.required]],
      email: [
        "",
        Validators.compose([
      
          Validators.pattern(common.emailPattern),
        ]),
      ],
      mobileno: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(common.phonePattern),
        ]),
      ],
      mobileno2: [
        "",
        Validators.compose([
         
          Validators.pattern(common.phonePattern),
        ]),
      ],
      publicmobileno: ["",
        Validators.compose([

          Validators.pattern(common.phonePattern),
        ]),],
      enabled: [""],
      password: [""],

      role: [""],
      contacttype: ["", Validators.required],
      latitude: [],
      longitude: [],
      showinapp:[],
      sequence:[],
    });
  }
  loadUtils(): void {

    this.utils.getUtils("units").then((res) => {
      this.units = res;
    });
    this.utils.getDesignation().then(res => {
      this.designation = res;
    })
  }
  get f() {
    return this.userForm.controls;
  }

  addUser() {
    if (this.userForm.invalid) {
      this.toastr.warning("Invalid details");
      return;
    }
    const vo = this.userForm.getRawValue();
    console.log(vo);
    this.service.addUser(vo).then(
      (res) => {
        this.toastr.success("Incharge successfully added");
        this.router.navigate(["../../"], { relativeTo: this.route });
      },
      (err) => {
        this.toastr.error(err);
      }
    );
  }
  updateUser() {
    if (this.userForm.invalid) {
      this.toastr.warning("Invalid details");
      return;
    }
    const vo = this.userForm.getRawValue();

    this.service.updateUser(vo).then(
      (res) => {
        this.toastr.success("Incharge successfully updated");
        this.router.navigate(["../../"], { relativeTo: this.route });
      },
      (err) => {
        this.toastr.error(err);
      }
    );
  }
  unitChanged(event) {
    const o = this.units.find((x) => x.id === event.value);
  }
  async getdata() {
    const data = await this.service.getUserById(this.id);
    return data;
  }

  sameMobileNumber($event) {
    if ($event.checked) {
      this.f.publicmobileno.setValue(this.f.mobileno.value);
      this.userForm.updateValueAndValidity();
    } else {
      this.f.publicmobileno.setValue(null);
      this.userForm.updateValueAndValidity();
    }
  }
  openLocationSelector() {
    const dialogRef = this.dailog.open(LocationSelectorComponent, {
      width: "50vw",
      data: {
        latitude: this.f.latitude.value,
        longitude: this.f.longitude.value,
      }
    });

    dialogRef.afterClosed().subscribe((x) => {
      if (x) {
        this.userForm.patchValue({ latitude: x.lat, longitude: x.lng });
      }
    });
  }
  listeners() {

    this.f.contacttype.valueChanges.subscribe(res => {
      if (res != 'ps') {
        this.userForm.patchValue({ latitude: null, longitude: null });
      }
    }
    );
  }
}
