import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LocationSelectorComponent } from "../helpers/location-selector/location-selector.component";
import { MatDialog } from "@angular/material/dialog";

import { UtilsService } from "../../services/utils.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { trigger, transition, style, animate } from "@angular/animations";
import {
  Component,
  OnInit,
  Output,
  HostListener,
  EventEmitter,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { DateSelectorComponent } from "../helpers/date-selector/date-selector.component";
import { ToastrService } from "ngx-toastr";
import { EventManageService } from "app/services/eventmanage.service";
import { ActivatedRoute, Router } from "@angular/router";
import { reseller } from "googleapis/build/src/apis/reseller";

@Component({
  selector: "app-event-details",
  templateUrl: "./event-details.component.html",
  styleUrls: ["./event-details.component.css"],
})
export class EventDetailsComponent implements OnInit, AfterViewInit {
  @Output() public event = new EventEmitter<any>();

  @ViewChild("dateselector", { static: false })
  dateselector: DateSelectorComponent;
  eventtypes: any;
  form: FormGroup;
  districts: any;
  locations: any;
  editMode = false;

  constructor(
    private _snackBar: MatSnackBar,
    private utils: UtilsService,
    private service: EventManageService,
    private dailog: MatDialog,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) {}
  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.initilaizeForm();
    this.loadUtils();
    this.listeners();
    this.getDataFromApi();
  }

  openLocationSelector() {
    const dialogRef = this.dailog.open(LocationSelectorComponent, {
      width: "50vw",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.f.eventlatitude.setValue(result.lat);
        this.f.eventlongitude.setValue(result.lng);
      }
    });
  }
  initilaizeForm(): any {
    this.form = this.formBuilder.group({
      id: [null],
      eventtype: [null, Validators.required],
      eventname: [null, Validators.required],
      eventdescription: [null, Validators.required],
      ismultidayevent: [false],
      isstateordistrict: [null],
      district: ["", Validators.required],
      isallocated: [false],
    });
  }
  get f() {
    return this.form.controls;
  }
  loadUtils(): void {
    /* TODO LOAD UTILS BASED ON ROLE */
    this.utils.getDistricts().then((res) => {
      this.districts = res;
    });
    this.utils.getEventTypes().then((res) => {
      this.eventtypes = res;
    });
  }

  listeners() {
    this.f.isstateordistrict.valueChanges.subscribe((x) => {
      this.f.district.setValue(null);
      this.f.district.updateValueAndValidity();
    });
  }
  creatEvent() {
    if (this.form.invalid || this.dateselector.form.invalid) {
      this.toastr.warning("Incomplete or Invalid details");
      return;
    }
    const dto = this.form.getRawValue();
    const o = this.dateselector.form.getRawValue();
    dto.locations = o.locations;
    
    this.service.addEvent(dto).then(
      (res) => {
        this.toastr.success("Successfully created");
        this.router.navigate(["../"], { relativeTo: this.route });
      },
      (err) => {
        this.toastr.error(err);
      }
    );
  }
  getDataFromApi() {
    this.route.params.subscribe((params) => {
      if (params.id) {
        this.editMode = true;
        this.service.getEventbyId(params.id).then((res) => {
          this.form.patchValue(res);
          const a = [];
          this.locations = res.locations;
          if (res.locations.length > 1) {
            for (let i = 0; i < res.locations.length; i++) {
              a.push(res.locations[i].districtid);
            }
            this.form.patchValue({ district: a });
            this.form.updateValueAndValidity();
          } else {
            this.form.patchValue({ district: res.locations[0].districtid });
          }
        });
      }
    });
  }
  updateEvent() {
    if (this.form.invalid || this.dateselector.form.invalid) {
      this.toastr.warning("Incomplete or Invalid details");
      return;
    }
    const dto = this.form.getRawValue();
    const o = this.dateselector.form.getRawValue();
    dto.locations = o.locations;
    this.service.updateEvent(dto).then(
      (res) => {
        this.toastr.success("Successfully updated");
        this.router.navigate(["../"], { relativeTo: this.route });
      },
      (err) => {
        this.toastr.error(err);
      }
    );
  }
}
