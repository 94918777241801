import { MatGoogleMapsAutocompleteModule } from "@angular-material-extensions/google-maps-autocomplete";
import { AngularMaterialModule } from "./../angular-material/angular-material.module";
import { AgmCoreModule } from "@agm/core";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { EventManagementRoutingModule } from "./event-management-routing.module";

import { EventDetailsComponent } from "./event-details/event-details.component";
import { LocationSelectorComponent } from "./helpers/location-selector/location-selector.component";

import {
  NgxMatDateFormats,
  NgxMatDatetimePickerModule,
  NGX_MAT_DATE_FORMATS,
} from "@angular-material-components/datetime-picker";

import { DateSelectorComponent } from "./helpers/date-selector/date-selector.component";
import { EventDetailsListComponent } from "./event-details-list/event-details-list.component";

import { VenueAllocationComponent } from "./venue-allocation/venue-allocation.component";
import { VenueSelectorsComponent } from "./helpers/venue-selectors/venue-selectors.component";
import { BandobustListComponent } from "./bandobust-list/bandobust-list.component";
import { BandobustComponent } from "./bandobust/bandobust.component";
import { VehicleSelectorComponent } from "./helpers/vehicle-selector/vehicle-selector.component";
import { NonDefSelectorComponent } from "./helpers/non-def-selector/non-def-selector.component";
import { BandobustSelectorComponent } from "./helpers/bandobust-selector/bandobust-selector.component";
import { AutoCompleteComponent } from "./helpers/auto-complete/auto-complete.component";
import { NgxMaterialTimepickerModule } from "ngx-material-timepicker";
import { FiltersModule } from "app/filters/filters.module";
import { PlacesCompleteComponent } from './helpers/places-complete/places-complete.component';

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: "l, LTS",
  },
  display: {
    dateInput: "l, LTS",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};
@NgModule({
  declarations: [
    EventDetailsComponent,
    LocationSelectorComponent,

    DateSelectorComponent,
    EventDetailsListComponent,

    VenueAllocationComponent,
    VenueSelectorsComponent,
    BandobustListComponent,
    BandobustComponent,
    VehicleSelectorComponent,
    NonDefSelectorComponent,
    BandobustSelectorComponent,
    AutoCompleteComponent,
    PlacesCompleteComponent,
    
  ],
  imports: [
    CommonModule,
    EventManagementRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyCdgCCvY4Y3h03OvXlswB976vvy33Ec2mg",
      libraries: ["places","geometry"],
    }),
    AngularMaterialModule,
    MatGoogleMapsAutocompleteModule,
    NgxMaterialTimepickerModule,
    NgxMatDatetimePickerModule,
    FiltersModule,
  ],
  providers: [{ provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }],
})
export class EventManagementModule {}
