import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EventFilterComponent } from "./event-filter/event-filter.component";
import { AngularMaterialModule } from "app/angular-material/angular-material.module";
import { DistrictPipe } from "app/pipes/districtpipe.pipe";
import { VenueFilterComponent } from './venue-filter/venue-filter.component';

@NgModule({
  declarations: [EventFilterComponent,DistrictPipe, VenueFilterComponent],
  imports: [CommonModule, AngularMaterialModule],
  exports: [EventFilterComponent],
})
export class FiltersModule {}
