import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular/material-moment-adapter";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DateValidators } from "app/pipes/DateValidator";
import { UtilsService } from "app/services/utils.service";

@Component({
  selector: "app-date-selector",
  templateUrl: "./date-selector.component.html",
  styleUrls: ["./date-selector.component.css"],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
})
export class DateSelectorComponent implements OnInit, OnChanges {
  @Input() data: any;
  districts: any;
  form: FormGroup;
  constructor(
    private _snackBar: MatSnackBar,
    private utils: UtilsService,

    private dailog: MatDialog,
    private formBuilder: FormBuilder
  ) {}
  ngOnChanges(changes: any): void {
    this.initializeForm();
  }

  ngOnInit(): void {
    this.getUtils();
  }
  getUtils() {
    this.utils.getDistricts().then((res) => {
      this.districts = res;
    });
  }
  initializeForm(): void {
    this.form = this.formBuilder.group({
      locations: new FormArray([]),
    });

    if (Array.isArray(this.data.districtlist)) {
      this.data.districtlist?.forEach((o) => {
        this.f.locations.push(this.initializeFormControls(o));
        this.f.locations.updateValueAndValidity();
      });
    } else {
      this.f.locations.push(
        this.initializeFormControls(this.data.districtlist)
      );
      this.f.locations.updateValueAndValidity();
    }
    if (this.data.locations.length > 0) {
      this.f.locations.patchValue(this.data.locations);
    }
  }
  initializeFormControls(o): any {
    return new FormGroup({
      id: new FormControl(null),
      districtid: new FormControl({ value: o, disabled: true }, [
        Validators.required,
      ]),
      startdate: new FormControl(null),
      enddate: new FormControl(null),
    }, {
      validators: Validators.compose([
        DateValidators.dateLessThan("startdate", "enddate", {
          startdate: true,
        }),
      
      ]),
    });
  }
  get f(): any {
    return this.form.controls;
  }
}
