import { AngularMaterialModule } from './../angular-material/angular-material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';

@NgModule({
  declarations: [LoginComponent, ForgotPasswordComponent, PrivacyPolicyComponent, TermsAndConditionsComponent],
  imports: [
    CommonModule,
    AuthRoutingModule,
    AngularMaterialModule,
    MatPasswordStrengthModule,
  ],
})
export class AuthModule {}
