import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';



@Injectable({
  providedIn: 'root',
})
export class OtpService {
  otpBaseurl = environment.base_url + 'auth/otp/';

  constructor(private http: HttpClient) {}

  generateOtp(mobile: string, otpvo): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
        mobileno: mobile,
      }),
    };
    return this.http
      .post<any>(this.otpBaseurl + 'generate', otpvo, httpOptions)
      .toPromise();
  }

  resetPassword(mobile: string, otpTemp: string, otpvo): any {
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: '*/*',
        mobileno: mobile,
        otp: otpTemp,
      }),
    };
    return  this.http
      .post<any>(this.otpBaseurl + 'reset-password', otpvo, httpOptions)
      .toPromise();
  }
}
