import { Component, OnInit } from "@angular/core";
import { single } from "app/pipes/single";
import { GraphService } from "app/services/graph.service";

@Component({
  selector: "app-linechart",
  templateUrl: "./linechart.component.html",
  styleUrls: ["./linechart.component.css"],
})
export class LinechartComponent implements OnInit {
  data: any[];
  // options
  gradient: boolean = true;
  showLegend: boolean = false;
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  legendPosition: string = "right";
  showXAxisLabel;
  showYAxisLabel;
  xAxisLabel;
  yAxisLabel;
  showXAxis;
  showYAxis;
  /* colorScheme = {
    domain: ["#5AA454", "#A10A28", "#C7B42C", "#AAAAAA"],
  };
 */
  constructor(private service: GraphService) {}
  ngOnInit(): void {
    this.service.checkPointsByDistrict().then((res) => {
      this.data = res;
    });
  }


  onSelect(data): void {
    
  }

  onActivate(data): void {
  
  }

  onDeactivate(data): void {
   
  }
}
