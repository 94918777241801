import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { UnauthorizedComponent } from "./../components/unauthorized/unauthorized.component";
import { AppUserComponent } from "./app-user/app-user.component";
import { InchargeManagementComponent } from "./incharge-management/incharge-management.component";
import { InchargeComponent } from "./incharge/incharge.component";
import { MenuManagementComponent } from "./menu-management/menu-management.component";
import { MenuSelectorComponent } from "./menu-selector/menu-selector.component";

const routes: Routes = [
  {
    path: "",
    component: InchargeManagementComponent,
    data: {
      title: "Manage Contacts",
    },
  },
  {
    path: "add-user",
    component: InchargeComponent,
    data: {
      title: "Manage Contacts",
    },
  },
  {
    path: "edit-user/:id",
    component: InchargeComponent,
    data: {
      title: "EDIT  Contacts",
    },
  },
 
  
  {
    path: "**",
    component: UnauthorizedComponent,
    data: {
      title: "Unauthorized",
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ManagementRoutingModule {}
